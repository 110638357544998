import * as React from "react";

import Layout from "../components/layout";

import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import logo from "../images/logo.png";

import Video from "../components/video";
import MailingList from "../components/mailinglist";
import frontPage from "../../content/front-page.yaml";

import { FaPhone, FaEnvelope } from "react-icons/fa";

import BlogPostCard from "../components/blogPostCard";

function Home({ location }) {
  const data = useStaticQuery(graphql`
    {
      Lewis: file(relativePath: { eq: "Lewis.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1000, maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beach: file(relativePath: { eq: "beach.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 500, maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      captain: file(relativePath: { eq: "captain.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1500, maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      showcase: allFile(filter: { relativeDirectory: { regex: "/^photos/" } }) {
        edges {
          node {
            name
            relativePath
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      blogPosts: allMarkdownRemark(limit: 3) {
        edges {
          node {
            frontmatter {
              title
              featuredText
              author
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
      contactBackground: file(relativePath: { eq: "sunset-3.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1000, maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const captain = data["captain"].childImageSharp.fluid;

  const beach = data["beach"].childImageSharp.fluid;

  const Lewis = data["Lewis"].childImageSharp.fluid;

  const contactBackground = data["contactBackground"].childImageSharp.fluid;

  const showcase = frontPage.photos.map((photoPath) =>
    data["showcase"].edges.find(
      ({ node }) => node.relativePath.slice(7) === photoPath
    )
  );

  // const showcase = data["showcase"].edges.filter(({ node }) =>
  //   frontPage.photos.includes(node.relativePath.slice(7))
  // );

  console.log(data["blogPosts"]);

  const blogPosts = data["blogPosts"].edges.filter(({ node }) =>
    frontPage.blogPosts.includes(node.frontmatter.title)
  );

  return (
    <Layout landing={true}>
      <div id="landing" /* Landing */ className="h-screen ">
        {/* <Header /> */}
        <Img
          fluid={captain}
          alt="landing"
          imgStyle={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
            filter: "brightness(0.7)",
            objectPosition: "52% 50%",
          }}
          className="h-0 "
        />
        <div className="w-full h-full flex px-5 sm:px-0 justify-center items-center absolute inset-0 ">
          <h2 className="text-5xl max-w-3xl mx-auto text-center ">
            "IF YOU CHANGE THE WAY YOU LOOK AT THINGS, THE THINGS YOU LOOK AT
            CHANGE"
          </h2>
        </div>
      </div>

      <div id="videos" className="flex flex-col py-10 space-y-5 m-auto px-5">
        <Video
          src={"https://www.youtube.com/embed/" + frontPage.videos[0].id}
          title={frontPage.videos[0].title}
          className="sm:w-full sm:p-10 max-w-5xl sm:m-auto"
        />
        <div className="flex flex-col sm:flex-row max-w-7xl w-full m-auto">
          <Video
            src={"https://www.youtube.com/embed/" + frontPage.videos[1].id}
            title={frontPage.videos[1].title}
            className="sm:w-1/2 sm:p-5"
          />
          <Video
            src={"https://www.youtube.com/embed/" + frontPage.videos[2].id}
            title={frontPage.videos[2].title}
            className="sm:w-1/2 sm:p-5"
          />
        </div>
      </div>

      <div id="about" /* About */>
        <h2 className="section-header">What is Visual Insight?</h2>
        <div className="relative ">
          <div className="absolute inset-0">
            <Img
              fluid={beach}
              alt="landing"
              imgStyle={{
                zIndex: -1,
                objectPosition: "72% 0",
                filter: "brightness(0.7)",
              }}
              className="h-full"
            />
          </div>
          <div className=" sm:p-20 relative flex flex-col sm:flex-row">
            <img
              src={logo}
              alt={logo}
              className=" sm:w-64 px-32 py-10 sm:p-0"
            />
            <p className="text-3xl text-white w-5/6 max-w-xl m-auto text-center pb-5 text-shadow">
              The world has so many powerful untold stories and we are a company
              that want to be a part of changing that. We want to be a part of
              you telling your story.
              <Link
                to="about"
                className="text-white text-base hover:underline block italic"
              >
                read more
              </Link>
            </p>
          </div>
        </div>
        <h2 className="section-header">Who is Lewis Seymour?</h2>
        <div className="flex flex-col sm:flex-row bg-black text-white w-full items-center border-t border-gray-500">
          <Img fluid={Lewis} alt="Lewis" className="w-full sm:w-1/2 h-full" />
          <p className="text-xl w-full sm:w-1/2 p-3 sm:p-10">
            Hi, my name is Lewis, I'm a young filmmaker with a passion to make a
            difference in the world. I want to tell stories, stories that make a
            difference, stories that change lives!
            <Link
              to="about"
              className="text-white text-base hover:underline block italic pt-5"
            >
              read more
            </Link>
          </p>
        </div>
      </div>

      <div id="photos" /* Photos */>
        <h2 className="section-header flex justify-between items-center">
          <div>Photos</div>
          <div className="normal-case text-lg  pr-2 sm:pr-5 text-right hover:underline">
            <Link to="/portfolio" className="font-Arial ">
              Click here to see entire portfolio
            </Link>
          </div>
        </h2>
        <div className="grid sm:grid-cols-6 grid-cols-2">
          {showcase.map(({ node }, index) => (
            <div
              key={index}
              className={
                node.childImageSharp.fluid.aspectRatio < 1 ? "row-span-2" : ""
              }
            >
              <Img
                fluid={node.childImageSharp.fluid}
                alt="img"
                className="h-full"
              />
            </div>
          ))}
        </div>
      </div>

      <div id="blog" /*blog*/>
        <h2 className="section-header text-center">Blog</h2>
        <div className="flex flex-col sm:flex-row items-center bg-black justify-around">
          {blogPosts.map(({ node }, index) => (
            <BlogPostCard post={node} key={index} />
          ))}
        </div>
      </div>
      <div id="contact" className="relative text-black text-3xl">
        <div className="absolute inset-0 -z-1">
          <Img
            fluid={contactBackground}
            alt="sunset"
            className="w-full h-full"
            imgStyle={{ objectPosition: "0 0" }}
          />
        </div>
        <h2 className="section-header text-white">Contact me</h2>
        <div className="flex flex-col sm:flex-row p-5 w-full justify-around">
          <div className="flex flex-col items-center bg-gray-200 bg-opacity-75 p-5 rounded-full mb-10">
            <FaEnvelope size="40" />{" "}
            <a
              href="mailto:lewis@thevisualinsight.co.za"
              className="max-w-full text-center break-words"
            >
              lewis@thevisualinsight.co.za
            </a>
          </div>
          <div className="flex flex-col items-center bg-gray-200 bg-opacity-75 p-5 rounded-full mb-10">
            <FaPhone size="40" /> <a href="tel:+27825995251">+27825995251</a>
          </div>
        </div>
        <div className="flex flex-col p-5 justify-center items-center sm:text-white italic ">
          <p className="max-w-lg text-shadow bg-gray-200 bg-opacity-75 p-5 rounded-full mb-10 text-center">
            Join my mailing list to get updates on new blog posts and other
            news:
          </p>
          <MailingList />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
